






































import { Component, Prop, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";

import "viewerjs/dist/viewer.css";
import { api } from "v-viewer";

type Tile = {
  name: string;
  type: {
    id: number;
    name: string;
  };
  contentable: {
    url: string;
  };
};

@Component
export default class CategoryComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;

  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView(`Category: ${this.tile?.name}`);
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  iframe(tile: Tile): boolean {
    if (tile.type.name != "iFrame") return false;

    return tile.contentable.url.match(/jpg|jpeg|png/g) != null;
  }

  show(url: string) {
    api({
      images: [url],
      options: {
        title: false,
        navbar: false,
        toolbar: false,
        toggleOnDblclick: false
      }
    });
  }
}
